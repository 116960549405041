<template>
    <div class="erro-404">
        <div class="error-cot">
            <img src="../assets/img/error_404.png" alt="">
            <p>sorry，找不到你想要的界面</p>
			<a href="https://beian.miit.gov.cn/">闽ICP备14019793号-8</a>
        </div>
		
    </div>
</template>
<script>
    export default {
        data(){
            return{}
        },
        created() {
        },
        methods:{

        }
    }
</script>
<style lang="less" scoped>
    .erro-404{
        width: 100vw;
        background: #fff;
        height: 100vh;
        position: relative;
        display: flex;
        align-items: center;
        .error-cot{
            width: 100%;
            > img{
                display: block;
                width: 100%;
            }
            >p{
                margin-top: 30px;
                text-align: center;
                font-size: 32px;
            }
			>a{
			display:block;
			text-align:center;
			margin-top:30px;
			font-size: 24px;
			}
        }

    }
</style>
